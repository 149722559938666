import React, { useState, useRef, useEffect } from "react";
import {
  getLanguages,
  addLanguage,
  deleteLanguage,
} from "../../../../api/apiCall.js";
import { FaCheckCircle } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";

function Language() {
  const user = JSON.parse(localStorage.getItem('user'));
  const storedLanguages = JSON.parse(localStorage.getItem('languages'));
  const token = localStorage.getItem('token');
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const [languages, setLanguages] = useState(storedLanguages)
  const [languagesArray, setLanguagesArray] = useState(storedLanguages)
  const inputRefs = useRef([]);
  const [loadId, setLoadId] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (updateSuccess) {
      const timer = setTimeout(() => {
        setVisible(false);
        setUpdateSuccess(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [updateSuccess]);

  const handleClose = () => {
    setVisible(false);
    setUpdateSuccess(false);
  };

  const handleGetLanguages = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
      }

      const response = await getLanguages(data);
      console.log("Languages: ", response)
      if (response.status === 200) {
        setLanguages(response.data);
        setLanguagesArray(response.data);
        localStorage.setItem("languages", JSON.stringify(response.data));
      }
    }
    catch (error) {
      console.log("Error getting languages: ", error);
    }
    finally {
      setLoadId(null);
      setIsAdding(false);
    }
  };

  const handleAddLanguage = async (index) => {
    setIsAdding(true);
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          language: languagesArray[index]
        }
      }

      const response = await addLanguage(data);
      if (response.status === 201) {
        // setLanguages((prevLanguages) => [...prevLanguages, languagesArray[index]]);
        handleGetLanguages();
      }
    }
    catch (error) {
      console.log("Error adding language: ", error);
    }
  }

  const handleDeleteLanguage = async (languageId) => {
    setLoadId(languageId);
    try {
      let data = {
        id: user.id,
        token: token,
        languageId: languageId
      }

      const response = await deleteLanguage(data);
      if (response.status === 200) {
        handleGetLanguages();
      }
    }
    catch (error) {
      console.log("Error deleteing language: ", error);
    }
  }

  const handleAddLanguageArray = () => {
    setLanguagesArray((prevLanguagesArray) => [...prevLanguagesArray, '']);
  };

  const handleChange = (e, index) => {
    const newKeywords = [...languagesArray];
    newKeywords[index] = e.target.value;
    setLanguagesArray(newKeywords);
  };

  useEffect(() => {
    handleGetLanguages();
  }, [])

  useEffect(() => {
    if (languagesArray.length > 0) {
      inputRefs.current[languagesArray.length - 1].focus();
    }
  }, [languagesArray]);

  return (
    <>
      <div className="flex flex-col pt-6 max-md:px-3 max-md:pt-0 pb-20 max-md:mt-8 max-md:max-w-full">
        <div className="flex flex-col text-base tracking-tight max-md:flex-wrap">
          <div className="leading-[110%] font-bold text-base mb-2">Add Language</div>
          <p className="text-base tracking-tight leading-4 text-neutral-400 max-md:max-w-full">Let us know what languages you speak! Adding them to your profile helps you connect with others and makes your experience more personal.</p>
        </div>
        <div className="mt-6 tracking-tight leading-[110%] text-neutral-600 max-md:max-w-full">
          Languages
        </div>
        <div className="flex gap-2 p-2.5 mt-2 text-sm leading-4 whitespace-nowrap rounded-lg border border-solid border-neutral-300 text-neutral-600 flex-wrap max-md:pr-5">
          {languagesArray.map((language, index) => (
            <div key={index} className="relative">
              {languages.includes(language) ? (
                <input
                  type="text"
                  className="justify-center px-4 py-2 rounded-lg bg-[#0082BA] text-white"
                  value={language.language}
                  disabled
                  onChange={(e) => handleChange(e, index)}
                  size={(language.language || '').length || 1}
                  ref={(el) => (inputRefs.current[index] = el)}
                />
              ) : (
                <input
                  type="text"
                  className="justify-center px-4 py-2 rounded-lg bg-neutral-100"
                  value={language.language}
                  onChange={(e) => handleChange(e, index)}
                  size={language.length || 1}
                  ref={(el) => (inputRefs.current[index] = el)}
                />
              )}

              {languages.includes(language) ? (
                <button
                  className="absolute top-[4px] right-1 px-2 py-1 text-white bg-red-500 rounded-full"
                  onClick={() => handleDeleteLanguage(language.id)}
                >
                  {loadId === language.id ? (
                    <div className="w-[15px] h-[15px] border-2 border-t-transparent border-white rounded-full animate-spin"></div>
                  ) : (
                    <span>x</span>
                  )}
                </button>
              ) : (
                <button
                  className="absolute top-[4px] right-1 px-2 py-1 text-white bg-green-500 rounded-full"
                  onClick={() => handleAddLanguage(index)}
                >
                  {isAdding ? (
                    <div className="w-[15px] h-[15px] border-2 border-t-transparent border-white rounded-full animate-spin max-md:w-[10px] max-md:h-[10px]"></div>
                  ) : (
                    <span>+</span>
                  )}
                </button>
              )}
            </div>
          ))}
          <button
            className={`justify-center px-4 py-2 max-md:px-4 max-md:py-1 rounded-full border border-neutral-200 bg-neutral-100 bg-gray-400 text-black ${(isAdding || loadId !== null) ? "opacity-50 cursor-not-allowed" : ""
              }`}
            onClick={handleAddLanguageArray}
            disabled={isAdding || loadId !== null}
          >
            +
          </button>
        </div>
      </div>
    </>
  );
}

export default Language;
