import React from 'react';
import ReturnButton from '../../../components/ReturnButton';
import { FiPlus } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';

const CategoryHeader = () => {
  const navigate = useNavigate();

  return (
    <header className="flex flex-wrap gap-10 justify-between items-center w-full max-md:max-w-full max-md:gap-5">
      <nav className="flex gap-0.5 items-center self-stretch my-auto text-sm leading-none text-sky-600">
      <ReturnButton pathName="Forum" url="/dashboard/forum" />
      </nav>
      <button onClick={() => navigate("/dashboard/forum/new-post")} className="flex gap-1 justify-center items-center self-stretch py-2.5 pr-5 pl-4 my-auto text-base font-medium text-gray-50 bg-blue-900 rounded-lg max-md:w-full">
        <FiPlus className="object-contain shrink-0 self-stretch my-auto"/>
        <span>Create Post</span>
      </button>
    </header>
  );
};

export default CategoryHeader;