import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";

function EmailVerified() {
  const closeTab = () => {
    window.close();
  };
  
  return (
    <div className="bg-[#F9FAFB] py-14 h-screen">
      <div className="h-[30vh] px-10">
        <div className="flex justify-center items-center flex-col mx-auto mt-20">
          <div className="flex justify-center items-center flex-col max-w-[730px] border border-gray-200 p-6 rounded-lg bg-white">
            <div className="flex items-center justify-center gap-3">
              <FaRegCheckCircle className="text-[45px] text-[#0082BA]"/>
              <div className="flex-1 text-[30px] font-extrabold text-[#1c3775]">
                Your Email has been verified!
              </div>
            </div>
            <div className="w-[80%] m-auto">
              <button
                onClick={closeTab}
                className="mt-7 w-full m-auto bg-[#1c3775] text-white px-7 py-2 rounded-full font-semibold text-lg "
              >
                Continue this tab
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailVerified;
