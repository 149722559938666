import React, { useState, useRef, useEffect } from "react";
import { HiXMark } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import {
    getCategories,
    getTags,
    addNewPost
} from "../../../../../api/apiCall";

function CreatePost() {
    const token = localStorage.getItem("token");
    const navigate = useNavigate();

    const [isAdding, setIsAdding] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState("");
    const [tagChoices, setTagChoices] = useState([]);
    const [tags, setTags] = useState([]); // Store tag IDs
    const [newTags, setNewTags] = useState([]); // Store new tag names
    const [inputTag, setInputTag] = useState("");
    const [filteredChoices, setFilteredChoices] = useState([]);
    const inputRef = useRef(null);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const initialFetch = async () => {
            await handleGetCategories();
            await handleGetTags();
        };

        initialFetch();
    }, []);

    const handleGetCategories = async () => {
        try {
            let data = { token };
            const response = await getCategories(data);
            if (response.status === 200) {
                setCategories(response.data.data);
            }
        } catch (error) {
            console.log("Error getting categories: ", error);
        }
    };

    const handleGetTags = async () => {
        try {
            let data = { token };
            const response = await getTags(data);
            if (response.status === 200) {
                setTagChoices(response.data.data);
            }
        } catch (error) {
            console.log("Error getting tags: ", error);
        }
    };

    const handleTagRemove = (tagToRemove) => {
        console.log("TYPE: ", typeof tagToRemove)
        switch(typeof tagToRemove){
            case "string":
                console.log("New Tag List: ", newTags);
                console.log("New Tag Remove: ", tagToRemove);
                setNewTags(newTags.filter(tag => tag !== tagToRemove));
                break;
            case "number":
                console.log("Tag List: ", tags);
                console.log("Tag Remove: ", tagToRemove);
                setTags(tags.filter(tagId => tagId !== tagToRemove));
                break;

            default:
                break
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputTag(value);

        setFilteredChoices(
            tagChoices.filter(tagChoice => 
                tagChoice.tag.toLowerCase().includes(value.toLowerCase())
            )
        );
    };

    const handleTagAdd = (tagToAdd) => {
        const tagChoice = tagChoices.find(tag => tag.tag.toLowerCase() === tagToAdd.toLowerCase());

        if (tagChoice) {
            if (!tags.includes(tagChoice.id)) {
                setTags([...tags, tagChoice.id]);
            }
        } 
        else {
            if (!newTags.includes(tagToAdd)) {
                setNewTags([...newTags, tagToAdd]);
            }
        }

        setInputTag("");
        setFilteredChoices([]);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleTagAdd(inputTag.trim());
        }
    };

    const handleAddPost = async () => {
        setIsAdding(true);
        try {
            let data = {
                token: token,
                payloads: {
                    subject: title,
                    content: description,
                    category_id: category,
                    tags,  // Existing tag IDs
                    new_tags: newTags // New tag names
                }
            };

            const response = await addNewPost(data);
            if (response.status === 201) {
                navigate(`/dashboard/forum/post/${response.data.data.id}`);
            }
        } catch (error) {
            console.error("Error adding post:", error);
        }
        finally{
            setIsAdding(false);
        }
    };

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    return (
        <div className="flex flex-1 shrink gap-6 px-8 py-6 basis-0 min-w-[240px] size-full max-md:px-5 max-md:max-w-full min-h-screen">
            <div className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px] max-md:max-w-full">
                <div className="flex flex-col w-full max-md:max-w-full">
                    <div className="flex flex-col w-full text-gray-400 max-md:max-w-full">
                        <div className="text-xs font-medium">
                            Topic Title <span className="text-gray-400">*</span>
                        </div>
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Title"
                            className="flex text-black items-center py-3 mt-1 w-full text-sm whitespace-nowrap rounded-lg border border-gray-400 border-solid px-3 max-md:max-w-full"
                        />
                    </div>

                    {/* Description */}
                    <div className="flex flex-col mt-6 w-full text-gray-400 min-h-[160px] max-md:max-w-full">
                        <div className="text-xs font-medium">
                            Description <span className="text-gray-400">*</span>
                        </div>
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Description"
                            className="flex text-black flex-1 items-start py-3 mt-1 text-sm rounded-lg border border-gray-400 border-solid px-3 w-full resize-none max-md:max-w-full"
                            rows="4"
                        />
                    </div>

                    {/* Category */}
                    <div className="flex flex-col mt-6 w-full text-gray-400 max-md:max-w-full">
                        <div className="text-xs font-medium">
                            Category <span className="text-gray-400">*</span>
                        </div>
                        <select 
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            className="flex flex-1 items-start py-3 mt-1 text-sm rounded-lg border border-gray-400 border-solid px-3 w-full max-md:max-w-full"
                        >
                            {categories.length > 0 ? (
                                categories.map((category) => (
                                    <option key={category.id} value={category.id}>{category.category}</option>
                                ))
                            ):(
                                <small>No Categories</small>
                            )}
                        </select>
                    </div>

                    {/* Tags */}
                    <div className="flex flex-col mt-6 w-full max-md:max-w-full relative">
                        <div className="text-xs font-medium text-gray-600">Tags</div>
                        <div className="flex items-center py-2.5 mt-1 w-full text-sm text-white rounded-lg border border-gray-400 border-solid bg-gray-100 rounded-lg relative">
                            <div className="flex flex-wrap gap-2 py-1 px-2">
                                {tags.map(tagId => {
                                    const tag = tagChoices.find(tc => tc.id === tagId);
                                    return (
                                        <div key={tagId} className="flex items-center gap-1 bg-sky-600 text-white rounded-full px-2 py-0.5">
                                            {tag?.tag || `Tag ${tagId}`} {/* Fallback if tag not found */}
                                            <button
                                                onClick={() => handleTagRemove(tagId)}
                                                className="text-white focus:outline-none"
                                            >
                                                <HiXMark className="h-4 w-4" />
                                            </button>
                                        </div>
                                    );
                                })}
                                {newTags.map(tagName => (
                                    <div key={tagName} className="flex items-center gap-1 bg-sky-600 text-white rounded-full px-2 py-0.5">
                                        {tagName}
                                        <button
                                            onClick={() => handleTagRemove(tagName)}
                                            className="text-white focus:outline-none"
                                        >
                                            <HiXMark className="h-4 w-4" />
                                        </button>
                                    </div>
                                ))}
                                <input
                                    ref={inputRef}
                                    type="text"
                                    value={inputTag}
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyDown}
                                    placeholder={tags.length === 0 ? "Add tags" : ""}
                                    className="flex-1 py-1 px-2 text-sm bg-transparent border-none outline-none text-black"
                                />
                            </div>
                        </div>

                        {/* Suggestions dropdown */}
                        {filteredChoices.length > 0 && (
                            <ul className="absolute left-0 mt-[75px] w-full bg-white border border-gray-300 rounded-lg shadow-lg z-10">
                                {filteredChoices.map((choice) => (
                                    <li
                                        key={choice.id}
                                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                                        onClick={() => handleTagAdd(choice.tag)}
                                    >
                                        {choice.tag}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>

                {/* Buttons */}
                <div className="flex gap-2 items-start self-end mt-6 text-base font-medium max-[425px]:flex-col-reverse max-[425px]:w-full max-[425px]:gap-4">
                    <button 
                        className="gap-2 self-stretch px-4 py-2.5 text-blue-900 whitespace-nowrap bg-indigo-100 rounded-lg w-[100px] max-[425px]:w-full"
                        onClick={() => navigate("/dashboard/forum/category-viewed")}
                    >
                        Cancel
                    </button>
                    <button 
                        className={`gap-2 self-stretch px-4 py-2.5 w-40 text-gray-50 bg-blue-900 rounded-lg max-[425px]:w-full ${isAdding ? 'opacity-[50%] cursor-not-allowed' : ''}`}
                        onClick={handleAddPost}
                        disabled={isAdding}
                    >
                        {isAdding ? "Creating" : "Create Post"}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CreatePost;
