import React, { useEffect, useState } from "react";
import { RxDashboard } from "react-icons/rx";
import { HiOutlineInbox } from "react-icons/hi2";
import { IoPersonOutline, IoTrophyOutline } from "react-icons/io5";
import { TbMessage } from "react-icons/tb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsLoggedIn, setIsLoading } from "../../../actions";
import { PiCoinVerticalThin } from "react-icons/pi";
import { IoSettingsOutline } from "react-icons/io5";
import {
  getTheRegisterMessages,
  getDevregMessages,
  getAdminMessages,
  logout,
} from "../../../api/apiCall.js";


function SidebarDashboard() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const earningsIcon = "/EarningsIcon.png";

  const handleLogout = async () => {
    dispatch(setIsLoading(true));
    try {
      let data = {
        id: user.id,
        token: token,
      }

      const response = await logout(data);
      if (response.status === 200) {
        localStorage.clear();
        dispatch(setIsLoggedIn(false));
        navigate("/");
        window.scrollTo(0, 0);
      }
    }
    catch (error) {
      console.error("Error logging out: ", error);
    }
    finally {
      dispatch(setIsLoading(false));
    }
  };

  const [isActive, setIsActive] = useState("");
  const [showSecondNav, setShowSecondNav] = useState(false);
  const listTab = [
    {
      title: "Overview",
      link: "?tab=overview",
      isActive: "overview",
    },
    {
      title: "Advertisement/Features",
      link: "?tab=advertisement-features",
      isActive: "advertisement-features",
    },
    {
      title: "Language",
      link: "?tab=language",

      isActive: "language",
    },
    {
      title: "Work History",
      link: "?tab=work-history",
      isActive: "work-history",
    },
    {
      title: "Portfolio",
      link: "?tab=portfolio",
      isActive: "portfolio",
    },
    {
      title: "Skills and Expertise",
      link: "?tab=skills-and-expertise",
      isActive: "skills-and-expertise",
    },
    {
      title: "Education / Certificate",
      link: "?tab=education-certificate",
      isActive: "education-certificate",
    },
    {
      title: "Honor and Awards",
      link: "?tab=honor-and-awards",
      isActive: "honor-and-awards",
    },

    {
      title: "Preview Profile",
      link: "/dashboard/profile",
      isActive: "preview-profile",
    },
  ];

  const [messageCount, setMessageCount] = useState({ theRegister: 0, devreg: 0, admin: 0 })
  const [activeTab, setActiveTab] = React.useState("");
  const [overview, setOverView] = React.useState("overview");
  const [markTheNav, setMarkTheNav] = React.useState(0);
  const location = useLocation();

  useEffect(() => {
    let intervalId;

    const init = async () => {
      await getMessagesCount();

      intervalId = setInterval(() => {
        setMessageCount(JSON.parse(localStorage.getItem("messages_count")) || messageCount);
      }, 1000);

    }

    init();

    return () => clearInterval(intervalId);

  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tabParam = urlParams.get("tab");

    if (location.pathname === "/dashboard/edit-profile") {
      setShowSecondNav(true);
      setActiveTab("editProfile");

      // Default to overview if no tab is specified
      if (!tabParam) {
        setOverView("overview");
        setIsActive("Overview");
        navigate("?tab=overview");
      } else {
        setOverView(tabParam);
        setIsActive(
          listTab.find(item => item.isActive === tabParam)?.title || "Overview"
        );
      }
    } else {
      setShowSecondNav(false);
    }

    if (location.pathname === "/dashboard/earnings-and-referrals") {
      setMarkTheNav(0);
      setActiveTab("earnings");
      setShowSecondNav(false);
    } else if (location.pathname === "/dashboard/messages") {
      if (tabParam === "admin") {
        setMarkTheNav(3);
        setActiveTab("messages");
      }
      if (tabParam === "theRegister") {
        setMarkTheNav(2);
        setActiveTab("messages");
      }
      if (tabParam === "devreg") {
        setMarkTheNav(1);
        setActiveTab("messages");
      }
      setActiveTab("messages");
      setShowSecondNav(false);
    } else if (location.pathname === "/dashboard/account-settings/help") {
      setMarkTheNav(6);
      setActiveTab("messages");
      setShowSecondNav(false);
    } else if (location.pathname === "/dashboard/news") {
      setMarkTheNav(0);
      setActiveTab("news");
      setShowSecondNav(false);
    } else if (location.pathname.startsWith("/dashboard/forum")) {
      setMarkTheNav(0);
      setActiveTab("forum");
      setShowSecondNav(false);
      if (location.pathname === "/dashboard/forum/saved-posts") {
      } else if (location.pathname === "/dashboard/forum/new-post") {
      } else if (location.pathname.startsWith("/dashboard/forum/post/")) {
      }
    } else if (location.pathname === "/dashboard/coin-profile") {
      setMarkTheNav(0);
      setActiveTab("coin");
      setShowSecondNav(false);
    } else if (location.pathname === "/dashboard/coin-profile/coin") {
      setMarkTheNav(0);
      setActiveTab("coin");
      setShowSecondNav(false);
    } else if (location.pathname.startsWith("/dashboard/coin-profile/coin/")) {
      setMarkTheNav(0);
      setActiveTab("coin");
      setShowSecondNav(false);
    } else if (location.pathname === "/dashboard/edit-profile") {
      setMarkTheNav(4);
      setShowSecondNav(true);
      setActiveTab("editProfile");
    } else if (location.pathname === "/dashboard/certificate") {
      setMarkTheNav(5);
      setShowSecondNav(false);

      setActiveTab("editProfile");
    } else if (location.pathname === "/dashboard/account-settings") {
      setMarkTheNav(6);
      setShowSecondNav(false);

      setActiveTab("accountSettings");
    } else if (location.pathname === "/dashboard/help") {
      setMarkTheNav(7);
      setShowSecondNav(false);
      setActiveTab("accountSettings");
    } else if (
      location.pathname === "/dashboard/account-settings/change-number"
    ) {
      setMarkTheNav(6);
      setShowSecondNav(false);
      setActiveTab("accountSettings");
    } else if (
      location.pathname === "/dashboard/account-settings/change-password"
    ) {
      setMarkTheNav(6);
      setShowSecondNav(false);
      setActiveTab("accountSettings");
    } else if (
      location.pathname === "/dashboard/account-settings/change-email"
    ) {
      setMarkTheNav(6);
      setShowSecondNav(false);
      setActiveTab("accountSettings");
    } else {
      setShowSecondNav(false);

      setMarkTheNav(0);
      setActiveTab("");
    }

    // Edit Profile

    if (tabParam === "overview") {
      setOverView("overview");
    }
    if (tabParam === "advertisement-features") {
      setOverView("advertisement-features");
    }
    if (tabParam === "language") {
      setOverView("language");
    }
    if (tabParam === "work-history") {
      setOverView("work-history");
    }
    if (tabParam === "portfolio") {
      setOverView("portfolio");
    }
    if (tabParam === "skills-and-expertise") {
      setOverView("skills-and-expertise");
    }
    if (tabParam === "education-certificate") {
      setOverView("education-certificate");
    }
    if (tabParam === "honor-and-awards") {
      setOverView("honor-and-awards");
    }
    if (tabParam === "preview-profile") {
      setOverView("preview-profile");
    }
  }, [location]);

  const getMessagesCount = async () => {
    try {
      let data = {
        id: user.id,
        token: token
      }
      const [responseTheRegister, responseDevreg, responseAdmin] = await Promise.all([
        getTheRegisterMessages(data),
        getDevregMessages(data),
        getAdminMessages(data)
      ]);
      setMessageCount({
        theRegister: responseTheRegister.data.total,
        devreg: responseDevreg.data.total,
        admin: responseAdmin.data.total
      });
      const counts = {
        theRegister: responseTheRegister.data.total,
        devreg: responseDevreg.data.total,
        admin: responseAdmin.data.total
      }
      localStorage.setItem("messages_count", JSON.stringify(counts));
    }
    catch (error) {
      console.error("Error fcounting messages:", error);
    }
    finally {

    }
  }

  return (
    <>
      <div
        className={`pb-14  min-h-[87vh] ${showSecondNav ? "min-w-[290px]" : "min-w-[310px]"
          } bg-devRegDarkBlue h-auto py-2 max-[900px]:hidden `}
      >
        <div className="title flex items-center gap-4 mb-2 px-5 py-3">
          <RxDashboard size={20} className="text-white" />
          <div className="font-normal text-lg text-white">
            Dashboard
          </div>
        </div>
        <div className="h-[0.8px] w-full bg-[#243D78] "></div>
        {/* navs */}
        <div className="dasboard-nav-cointainter">
          {/* <div
            className="title flex items-center gap-4  text-white text-lg py-4  px-5 cursor-pointer"
            onClick={() => navigate("/dashboard/news")}
          >
            <img
              loading="lazy"
              src="/newsIcon.png"
              alt="earnings logo"
              className={`w-5 h-5 text-white `}
            />
            <div
              className={`pl-1 ${
                activeTab === "news" ? " font-extrabold" : "font-normal"
              }  `}
            >
              News
            </div>
          </div> */}

          {/* Forums */}
          <div
            onClick={() => navigate("/dashboard/forum")}
            className={`title flex items-center gap-4 text-white text-lg py-4 cursor-pointer px-5 ${activeTab === "forum" ? "bg-[#0082ba]" : ""
              }`}
          >
            <TbMessage color="white" />

            <div
              className={`${activeTab === "forum" ? "font-extrabold" : "font-normal"
                } pl-1 cursor-pointer`}
            >
              Forum
            </div>
          </div>

          <div className="h-[0.8px] w-full bg-[#243D78] "></div>

          {/* Messages */}
          <div
            className={`title flex items-center gap-4 py-4   ${activeTab === "messages" ? "font-bold bg-[#0082ba]" : "font-normal"
              } text-lg py-2 cursor-pointer px-5 `}
            onClick={() => {
              navigate("/dashboard/messages?tab=theRegister");
            }}
          >
            <HiOutlineInbox color="white" />
            <div className="pl-1  text-white">Messages</div>
          </div>
          <div className=" text-white">
            <div
              className={`flex items-center justify-between px-14 py-3 cursor-pointer  ${markTheNav === 1 ? "text-white font-semibold bg-[#0082ba]" : "font-normal"
                }`}
              onClick={() => {
                navigate("/dashboard/messages?tab=devreg");
              }}
            >
              <div className="">DevReg</div>
              <div className="">{messageCount.devreg}</div>
            </div>
            <div className="h-[0.8px] w-full bg-[#243D78] "></div>
            <div
              className={`flex items-center justify-between px-14 py-3 cursor-pointer  ${markTheNav === 2 ? "text-white font-semibold bg-[#0082ba]" : "text-white"
                }`}
              onClick={() => {
                navigate("/dashboard/messages?tab=theRegister");
              }}
            >
              <div className="">The Register</div>
              <div className="">{messageCount.theRegister}</div>
            </div>
            <div className="h-[0.8px] w-full bg-[#243D78] "></div>
            <div
              className={`flex items-center justify-between px-14 py-3 cursor-pointer  ${markTheNav === 3 ? "text-white font-semibold bg-[#0082ba]" : "text-white"
                }`}
              onClick={() => {
                // Navigate to the messages route with the admin tab parameter
                navigate("/dashboard/messages?tab=admin");
              }}
            >
              <div className="">Admin</div>
              <div className="">{messageCount.admin}</div>
            </div>
          </div>
          <div className="h-[0.8px] w-full bg-[#243D78] "></div>

          {/* Earn and Refs */}
          <div
            className={`title flex items-center gap-4  text-white text-lg py-4  px-5 cursor-pointer ${activeTab === "earnings" ? "bg-[#0082ba]" : ""}`}
            onClick={() => navigate("/dashboard/earnings-and-referrals")}
          >
            <IoTrophyOutline color="white" />

            <div
              className={`pl-1 ${activeTab === "earnings" ? " font-extrabold" : "font-normal"
                }  `}
            >
              Earnings and Referrals
            </div>
          </div>

          <div className="h-[0.8px] w-full bg-[#243D78] "></div>

          {/* Professional Profile */}
          <div className={`title flex items-center gap-4  text-white text-lg py-4 px-5 cursor-pointer ${activeTab === "editProfile" ? "bg-[#0082ba]" : ""}`}>
            <IoPersonOutline color="white" />

            <div
              className={`pl-1  ${activeTab === "editProfile" ? "font-extrabold" : "font-normal "
                } `}
              onClick={() => navigate("/dashboard/edit-profile")}
            >
              Professional Profile
            </div>
          </div>
          <div className="text-white">
            <div
              className={`flex items-center cursor-pointer px-14 py-3 ${markTheNav === 4 ? "text-white font-semibold bg-[#0082ba]" : "font-normal"
                }`}
              onClick={() => navigate("/dashboard/edit-profile")}
            >
              <div className="cursor-pointer">Edit Profile</div>
            </div>
            <div className="h-[0.8px] w-full bg-[#243D78] "></div>
            <div
              className={`flex items-center cursor-pointer py-3 px-14 ${markTheNav === 5 ? "text-white font-semibold bg-[#0082ba]" : "text-white"
                }`}
              onClick={() => navigate("/dashboard/certificate")}
            >
              <div className="cursor-pointer">Download Certificate</div>
            </div>
          </div>
          <div className="h-[0.8px] w-full bg-[#243D78] "></div>

          {/* Coins */}
          <div
            onClick={() => navigate("/dashboard/coin-profile")}
            className={`title flex items-center gap-4  text-white text-lg py-4 cursor-pointer px-5 ${activeTab === "coin" ? "bg-[#0082ba]" : ""}`}
          >
            <PiCoinVerticalThin color="white" />

            <div
              className={`${activeTab === "coin" ? " font-extrabold" : "font-normal"
                } pl-1 cursor-pointer`}
            >
              Coin Profile
            </div>
          </div>

          <div className="h-[0.8px] w-full bg-[#243D78] "></div>

          {/* Account Settings */}
          <div
            className={`title text-white flex items-center gap-4 text-lg py-4 cursor-pointer px-5 
            ${activeTab === "accountSettings"
                ? " font-extrabold bg-[#0082ba]"
                : "font-normal"
              }`}
            onClick={() => navigate("/dashboard/account-settings")}
          >
            <IoSettingsOutline color="white" />

            <div className="pl-1 cursor-pointer ">Account Settings</div>
          </div>
          <div className="text-white">
            <div
              className={`flex items-center cursor-pointer px-14 py-3 ${markTheNav === 6 ? "font-semibold bg-[#0082ba]" : "font-normal"
                }`}
              onClick={() => navigate("/dashboard/account-settings")}
            >
              <div
                className=""
              >
                Security Setting
              </div>
            </div>
            <div className="h-[0.8px] w-full bg-[#243D78] "></div>
            <div
              className={`flex items-center cursor-pointer py-3 px-14 ${markTheNav === 7 ? "font-semibold bg-[#0082ba]" : "font-normal"
                } `}
              onClick={() => navigate("/dashboard/help")}
            >
              <div
                className=""
              >
                Help
              </div>
            </div>
            <div className="h-[0.8px] w-full bg-[#243D78] "></div>
            <div
              className={`flex items-center  py-3 px-14 ${markTheNav === 8 ? "font-semibold bg-[#0082ba]" : "text-white"
                } `}
            >
              <div onClick={handleLogout} className="cursor-pointer">
                Log Out
              </div>
            </div>
          </div>
          <div className="h-[0.8px] w-full bg-[#243D78] "></div>
        </div>
      </div>
      {showSecondNav && (
        <>
          <div className="min-w-[200px] bg-[#F2F7FF]  shadow-[rgba(0,0,15,0.1)_-10px_0px_20px_2px] max-[900px]:hidden">
            {listTab.map((item, index) => (
              <div
                key={index}
                className={`flex items-center justify-start cursor-pointer ${item.title === isActive
                  ? "bg-white font-extrabold"
                  : "bg-[#F2F7FF]"
                  } `}
                onClick={() => {
                  navigate(item.link);
                  setIsActive(item.title);
                }}
              >
                <div
                  className={`h-12 w-[7px] ${overview === item.isActive
                    ? "bg-devRegGreen"
                    : "bg-[#F2F7FF]"
                    } `}
                ></div>
                <div className="text-sm text-neutral-700 pl-4">
                  {item.title}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default SidebarDashboard;
