import React from 'react';

const CategoryTopic = ({ topics }) => {
  return (
    <section className="flex overflow-hidden flex-col mt-6 w-full text-sm rounded-lg border border border-solid max-md:max-w-full overflow-x-auto">
      {/* Header Section */}
      <div className="flex flex-nowrap items-center px-4 py-2 w-full font-semibold text-white bg-blue-900 min-w-[588px]">
        <div className="self-stretch my-auto whitespace-nowrap min-w-[180px] w-[400px] max-md:w-0">Subject</div>
        <div className="gap-2.5 self-stretch pl-2 my-auto w-60 min-w-[115px] max-md:w-0">Started by</div>
        <div className="flex-1 shrink gap-2.5 self-stretch pl-2 my-auto whitespace-nowrap min-w-[70px] max-md:pl-0">Replies</div>
        <div className="flex-1 shrink gap-2.5 self-stretch pl-2 my-auto whitespace-nowrap min-w-[70px] max-md:pl-0">Views</div>
        <div className="gap-2.5 self-stretch pl-2 my-auto w-60 max-md:pl-0">Last Post</div>
      </div>

      {/* Topics Section */}
      <div className="flex flex-col w-full max-md:max-w-full">
        {topics.map((topic, index) => (
          <div key={index} className="flex items-center px-4 w-full bg-white border-b border-solid max-md:max-w-full min-w-[588px]">
            <div className="flex flex-nowrap flex-1 shrink self-stretch my-auto w-full basis-0 min-w-[570px] max-md:max-w-full">
              <div className="flex items-center pr-2 text-blue-900 min-w-[180px] w-[400px]">{topic.subject}</div>
              <div className="flex items-center px-2 w-60 text-blue-900 whitespace-nowrap bg-gray-100 min-w-[110px]">{topic.startedBy}</div>
              <div className="flex items-center flex-1 shrink px-2 text-blue-900 whitespace-nowrap min-w-[70px]">{topic.replies}</div>
              <div className="flex items-center flex-1 shrink px-2 text-blue-900 whitespace-nowrap bg-gray-100 min-w-[70px]">{topic.views}</div>
              <div className="flex flex-col justify-center p-2 w-60 text-xs min-w-[140px]">
                <div className="text-gray-600">{topic.lastPost.date}</div>
                <div className="text-sky-600">
                  by <span className="font-medium text-sky-600">{topic.lastPost.author}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CategoryTopic;
