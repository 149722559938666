import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "../pages/Basic/Home/Home";
import WhyRegister from "../pages/Basic/whyRegister/WhyRegister";
import BenefitPageMain from "../pages/Basic/benefit/BenefitPageMain";
import NavigationBar from "../pages/Basic/components/NavigationBar";
import Footer from "../pages/Basic/components/Footer";
import RequirementsMain from "../pages/Basic/requirements/RequirementsMain";
import AboutUsMain from "../pages/Basic/aboutUs/AboutUsMain";
import AboutProfile from "../pages/Basic/aboutProfile/AboutProfile";
import Faq from "../pages/Basic/faq/Faq";
import ContactMain from "../pages/Basic/contact/ContactMain";
import PrivacyMain from "../pages/Basic/privacy/PrivacyMain";
import AntibriberyMain from "../pages/Basic/antibriberyPolicy/AntibriberyMain";
import TermsAndConditionMain from "../pages/Basic/termsAndCondition/TermsAndConditionMain";
import LoginMain from "../pages/Login/LoginMain";
import ForgotPassMain from "../pages/Login/forgotPassword/ForgotPassMain";
import PasswordSuccess from "../pages/Login/forgotPassword/PassUpdateSuccess";

import QRScan from "../pages/Login/2fa/QRScan";
import OTPVerify from "../pages/Login/2fa/OTPVerify";
import RecoveryCodes from "../pages/Login/2fa/RecoveryCodes";
import TwoFAFromApp from "../pages/Login/2fa/TwoFAFromApp";
import EnterRecoveryCodes from "../pages/Login/2fa/EnterRecoveryCodes";
import EnterCodeAccess from "../pages/Login/accessKey/EnterCodeAccess";
import UploadDocumentsReq from "../pages/Login/accessKey/UploadDocumentsReq";
import UploadSuccess from "../pages/Login/accessKey/UploadSuccess";
import Registration from "../pages/Registration/Registration";
import PersonalInformation from "../pages/Registration/PersonalInformation";
import Stripe from "../pages/Registration/Stripe";
import Thankyou from "../pages/Registration/Thankyou";
import UploadDocuments from "../pages/Registration/UploadDocuments";
import FacialRecognition from "../pages/Registration/FacialRecognition";
import Interview from "../pages/Registration/Interview";
import RegistrationSuccess from "../pages/Registration/RegistrationSuccess";
import RegistrationNavbar from "../pages/Registration/components/RegistrationNavbar";
import GuardianInformation from "../pages/Registration/minorApplicant/GuardianInformation";
// import PaymentMethod from "../pages/Registration/minorApplicant/PaymentMethod";
import FacialRecognitionApproved from "../pages/Registration/minorApplicant/FacialRecognitionApproved";
import ScanFace from "../pages/Registration/FaceScanner/ScanFace";
import EmailVerified from "../pages/Registration/EmailVerified";
import EmailVerifiedFailed from "../pages/Registration/EmailVerifiedFailed";
import PageNotFound from "../../src/PageNotFound";
import RegistrationDone from "../pages/Registration/RegistrationDone";

function NotLoggedInNavigations() {
  const [pageToLogin, setPageToLogin] = useState(false);
  const [showRegistrationNav, setShowRegistrationNav] = useState(false);
  const location = useLocation();

  const loginPaths = [
    "/login", 
    "/forgot-password",
    "/qr-scan", 
    "/otp-verify", 
    "/recovery-codes", 
    "/2fa-authApp",
    "/enter-recovery-codes", 
    "/enter-access-key", 
    "/upload-documentsreq", 
    "/upload-success",
    "/register",
    "/password-success",
  ];

  const registrationNavPaths = [
    "/personal-information",
    "/payment",
    "/thankyou",
    "/upload-documents",
    "/face-recognition",
    "/interview",
    "/registration-success",
    "/registration-done",
    "/guardian-information",
    // "/payment-method",
    "/face-recognition-minor",
    "/email-verification/success",
    "/email-verification/failed",
  ];

  useEffect(() => {
    const isLoginPage = loginPaths.includes(location.pathname);
    const isRegistrationNavPage = registrationNavPaths.includes(location.pathname);
    const isScanFacePage = location.pathname.startsWith("/face-scan/");

    if (isLoginPage || isRegistrationNavPage || isScanFacePage) {
      setPageToLogin(true);
      setShowRegistrationNav(isRegistrationNavPage && !isScanFacePage);
    } else {
      setPageToLogin(false);
      setShowRegistrationNav(false);
    }
  }, [location]);

  return (
    <>
      {!pageToLogin && <NavigationBar />}
      {showRegistrationNav && <RegistrationNavbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/why-register" element={<WhyRegister />} />
        <Route path="/benefits" element={<BenefitPageMain />} />
        <Route path="/requirements" element={<RequirementsMain />} />
        <Route path="/about-us" element={<AboutUsMain />} />
        <Route path="/about-profile" element={<AboutProfile />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact" element={<ContactMain />} />
        <Route path="/privacy" element={<PrivacyMain />} />
        <Route path="/anti-bribery-policy" element={<AntibriberyMain />} />
        <Route path="/login" element={<LoginMain />} />
        <Route path="/forgot-password" element={<ForgotPassMain />} />
        <Route path="/password-success" element={<PasswordSuccess />} />

        <Route path="/qr-scan" element={<QRScan />} />
        <Route path="/otp-verify" element={<OTPVerify />} />
        <Route path="/recovery-codes" element={<RecoveryCodes />} />
        <Route path="/2fa-authApp" element={<TwoFAFromApp />} />
        <Route path="/enter-recovery-codes" element={<EnterRecoveryCodes />} />
        <Route path="/enter-access-key" element={<EnterCodeAccess />} />
        <Route path="/upload-documentsreq" element={<UploadDocumentsReq />} />
        <Route path="/upload-success" element={<UploadSuccess />} />
        <Route path="/register" element={<Registration />} />
        <Route path="/email-verified" element={<EmailVerified />} />
        <Route path="/email-verification/failed" element={<EmailVerifiedFailed />} />
        <Route path="/personal-information" element={<PersonalInformation />} />
        <Route path="/payment" element={<Stripe />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/upload-documents" element={<UploadDocuments />} />
        <Route path="/face-recognition" element={<FacialRecognition />} />
        <Route path="/face-scan/:key" element={<ScanFace />} />
        <Route path="/interview" element={<Interview />} />
        <Route path="/registration-success" element={<RegistrationSuccess />} />
        <Route path="/registration-done" element={<RegistrationDone />} />
        <Route path="/guardian-information" element={<GuardianInformation />} />
        {/* <Route path="/payment-method" element={<PaymentMethod />} /> */}
        <Route path="/face-recognition-minor" element={<FacialRecognitionApproved />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditionMain />} />
        
        {/* Wildcard route for handling 404 - Page Not Found */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {!pageToLogin && <Footer />}
    </>
  );
}

export default NotLoggedInNavigations;

